import React from "react";
import {
  Typography,
  Grid,
  MenuItem,
  IconButton,
  styled,
  Box,
  Divider,
  colors,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { selectActiveSession } from "app/modules/schools/selectors";
import { useSelector } from "react-redux";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import CustomLoader from "app/components/common/CustomLoader";
import { IndianCurrency } from "app/utils/helper";
import { Check, Close } from "@mui/icons-material";
import {
  addPayrollTransaction,
  deletePayrollTransaction,
  updatePayrollTransaction,
} from "app/services/schoolService/hr.service";
import TableActionButtons from "app/components/common/TableActionButtons";
import DeleteConfirmationDialog from "app/components/common/Dialogs/DeleteConfirmationDialog";
import { PAYMENT_MODE } from "app/config/constants";

const TableForEarningDeduction = ({
  getStaffCalculatedDebtData = {},
  setDebtState,
  tableData = [],
  headType = "",
  payrollHead = [],
  refetch = () => {},
  staffDocId = "",
  totalText = "",
  total = "",
  month = "",
  year = "",
}) => {
  const headPresent = tableData?.map((item) => item?.payrollHeadDocId);
  const { id: payrollDocId } = useParams();
  const selectedSession = useSelector(selectActiveSession);
  const [state, setState] = useState({
    add_headName: "",
    add_config: "",
    add_adjusted: "",
    add_amount: "",
    add_paymentMode: "",
    add_remark: "",
    editPayrollDocId: "",
    deleteDocId: "",
    edit: false,
    edit_headName: "",
    edit_config: "",
    edit_adjusted: "",
    edit_amount: "",
    edit_paymentMode: "",
    edit_remark: "",
  });
  const handleCloseEdit = () => {
    setState((prev) => ({
      ...prev,
      editPayrollDocId: "",
      edit: false,
      edit_headName: "",
      edit_config: "",
      edit_adjusted: "",
      edit_amount: "",
      edit_remark: "",
    }));
  };
  const handleCloseAdd = () => {
    setState((prev) => ({
      ...prev,
      add_headName: "",
      add_config: "",
      add_adjusted: "",
      add_amount: "",
      add_remark: "",
    }));
  };
  const handleAdd = () => {
    if (!String(state.add_headName).trim()) {
      toast.error("Head Name is required");
      return;
    }
    if (
      !String(state.add_config).trim() &&
      !String(state.add_adjusted).trim()
    ) {
      toast.error("Amount is required");
      return;
    }
    addPayrollTransactionMutate({
      session: selectedSession,
      staffDocId: staffDocId,
      payrollHeadDocId: state.add_headName,
      payrollDocId,
      year,
      month,
      paymentMode: state.add_paymentMode,
      config: Number(state.add_config),
      deduction: Number(state.add_adjusted),
      amount: Number(state.add_amount),
      remarks: state.add_remark,
    });
  };
  const handleUpdate = () => {
    updatePayrollTransactionMutate({
      payrollTransactionDocId: state.editPayrollDocId,
      session: selectedSession,
      year,
      month,
      paymentMode: state.edit_paymentMode,
      config: Number(state.edit_config),
      deduction: Number(state.edit_adjusted),
      amount: Number(state.edit_amount),
      remarks: state.edit_remark,
    });
  };
  const handleDelete = () => {
    deletePayrollTransactionMutate(state.deleteDocId);
  };
  const handleCloseDelete = () => {
    setState((prev) => ({ ...prev, deleteDocId: false }));
  };
  // update payroll transaction
  const {
    mutate: addPayrollTransactionMutate,
    isLoading: addPayrollTransactionLoading,
  } = useMutation({
    mutationKey: ["addPayrollTransaction"],
    mutationFn: addPayrollTransaction,
    onSuccess: (success) => {
      refetch();
      toast.success(success?.data?.message);
      handleCloseAdd();
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const {
    mutate: updatePayrollTransactionMutate,
    isLoading: updatePayrollTransactionLoading,
  } = useMutation({
    mutationKey: ["updatePayrollTransaction"],
    mutationFn: updatePayrollTransaction,
    onSuccess: (success) => {
      refetch();
      handleCloseEdit();
      toast.success(success?.data?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  // delete payroll transaction
  const {
    mutate: deletePayrollTransactionMutate,
    isLoading: deletePayrollTransactionLoading,
  } = useMutation({
    mutationKey: ["deletePayrollTransaction"],
    mutationFn: deletePayrollTransaction,
    onSuccess: (success) => {
      refetch();
      handleCloseDelete();
      toast.success(success?.data?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  return (
    <>
      <CustomLoader
        show={addPayrollTransactionLoading || updatePayrollTransactionLoading}
      />
      <DeleteConfirmationDialog
        open={state.deleteDocId ? true : false}
        handleDelete={handleDelete}
        onClose={handleCloseDelete}
        loading={deletePayrollTransactionLoading}
      />
      <TableWrapper>
        <Grid
          container
          spacing={1}
          columns={15}
          className="grid__head"
          alignItems={"center"}
        >
          <Grid item xs={1}>
            <Typography className="head_text">Sr No.</Typography>
          </Grid>
          <Grid item xs={2.5}>
            <Typography className="head_text">Head Name</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className="head_text">Gross</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className="head_text">Sort Attend</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className="head_text">Net Amount</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className="head_text">Payment Mode</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className="head_text">Remarks</Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography className="head_text">Actions</Typography>
          </Grid>
        </Grid>

        {tableData?.map((item, ind) =>
          state.edit === item?._id ? (
            <Box key={ind}>
              <Grid
                container
                spacing={1}
                columns={15}
                className="grid_content"
                alignItems={"center"}
              >
                <Grid item xs={1} />
                <Grid item xs={2.5}>
                  <CustomSelectBox
                    displayEmpty
                    fullWidth
                    size="small"
                    value={state.edit_headName}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        edit_headName: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="" disabled>
                      Head Name
                    </MenuItem>
                    {payrollHead?.map((head) => (
                      <MenuItem key={head?._id} value={head?._id}>
                        {head?.headName}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Grid>
                <Grid item xs={2}>
                  <CustomTextField
                    fullWidth
                    type="number"
                    placeholder="config amount"
                    value={state.edit_config}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        edit_config: e.target.value,
                        edit_amount:
                          Number(e.target.value) -
                          Number(state.edit_adjusted ?? 0),
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <CustomTextField
                    fullWidth
                    type="number"
                    placeholder="adjusted amount"
                    value={state.edit_adjusted}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        edit_adjusted: e.target.value,
                        edit_amount:
                          Number(state.edit_config ?? 0) -
                          Number(e.target.value),
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <Typography className="value_text">
                    {IndianCurrency(Number(state.edit_amount))}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <CustomSelectBox
                    native
                    displayEmpty
                    size="small"
                    fullWidth
                    value={state.edit_paymentMode}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        edit_paymentMode: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="" component="option">
                      Select
                    </MenuItem>
                    {PAYMENT_MODE?.map((item) => (
                      <MenuItem
                        value={item?.value}
                        key={item?.value}
                        component="option"
                      >
                        {item?.name}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Grid>
                <Grid item xs={2}>
                  <CustomTextField
                    fullWidth
                    type="text"
                    placeholder="Type remark"
                    value={state.edit_remark}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        edit_remark: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item xs={1.5}>
                  <IconButton onClick={handleUpdate}>
                    <Check color="success" />
                  </IconButton>
                  <IconButton onClick={handleCloseEdit}>
                    <Close color="error" />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider sx={{ background: "#444", my: 0.5 }} />
            </Box>
          ) : (
            <Box key={ind}>
              <Grid
                key={ind}
                container
                spacing={1}
                columns={15}
                className="grid_content"
                alignItems={"center"}
              >
                <Grid item xs={1}>
                  <Typography className="value_text">
                    {ind + 1 < 10 ? `0${ind + 1}` : ind + 1}
                  </Typography>
                </Grid>
                <Grid item xs={2.5}>
                  <Typography className="value_text">
                    {item?.headName}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="value_text">
                    {IndianCurrency(item?.config)}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="value_text">
                    {IndianCurrency(item?.deduction)}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    className="value_text"
                    sx={{
                      color:
                        headType === "Earning"
                          ? colors.green[400]
                          : colors.red[600],
                    }}
                  >
                    {IndianCurrency(Number(item?.amount))}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="value_text">
                    {item?.paymentMode}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography className="value_text">
                    {item?.remarks}
                  </Typography>
                </Grid>
                <Grid item xs={1.5}>
                  <TableActionButtons
                    showEdit
                    showDelete
                    handleDelete={() =>
                      setState((prev) => ({
                        ...prev,
                        deleteDocId: item?._id,
                      }))
                    }
                    handleEdit={() =>
                      setState((prev) => ({
                        ...prev,
                        editPayrollDocId: item?._id,
                        edit: item?._id,
                        edit_headName: item?.payrollHeadDocId,
                        edit_config: item?.config,
                        edit_adjusted: item?.deduction,
                        edit_amount: item?.amount,
                        edit_remark: item?.remarks,
                        edit_paymentMode: item?.paymentMode,
                      }))
                    }
                  />
                </Grid>
              </Grid>
              <Divider sx={{ background: "#444", my: 0.5 }} />
            </Box>
          )
        )}
        {/* input fields for add  */}
        <Grid
          container
          spacing={1}
          columns={15}
          mb={1}
          mt={0.5}
          alignItems={"center"}
        >
          <Grid item xs={1} />
          <Grid item xs={2.5}>
            <CustomSelectBox
              displayEmpty
              fullWidth
              size="small"
              value={state.add_headName}
              onChange={(e) =>
                setState((prev) => ({ ...prev, add_headName: e.target.value }))
              }
            >
              <MenuItem value="" disabled>
                Head Name
              </MenuItem>
              {payrollHead
                ?.filter((item) => !headPresent.includes(item?._id))
                ?.map((head) => (
                  <MenuItem key={head?._id} value={head?._id}>
                    {head?.headName}
                  </MenuItem>
                ))}
            </CustomSelectBox>
          </Grid>

          <Grid item xs={2}>
            <CustomTextField
              fullWidth
              type="number"
              placeholder="config amount"
              value={state.add_config}
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  add_config: e.target.value,
                  add_amount:
                    Number(e.target.value) - Number(state.add_adjusted ?? 0),
                }));
                const filteredHead = payrollHead.find(
                  (res) => res?._id === state.add_headName
                );

                if (
                  state.add_headName &&
                  ["advance payment pay", "advance payment deduction"].includes(
                    String(filteredHead?.headName).toLocaleLowerCase()
                  )
                ) {
                  setDebtState((prev) => ({
                    ...prev,
                    ...(headType === "Deduction" && {
                      depositAmount:
                        Number(getStaffCalculatedDebtData?.depositAmount ?? 0) +
                        Number(e.target.value) -
                        Number(state.add_adjusted ?? 0),
                    }),
                    ...(headType === "Earning" && {
                      withdrawalAmount:
                        Number(
                          getStaffCalculatedDebtData?.withdrawalAmount ?? 0
                        ) -
                        Number(e.target.value) -
                        Number(state.add_adjusted ?? 0),
                    }),
                  }));
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <CustomTextField
              fullWidth
              type="number"
              placeholder="adjusted amount"
              value={state.add_adjusted}
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  add_adjusted: e.target.value,
                  add_amount:
                    Number(state?.add_config ?? 0) - Number(e.target.value),
                }));
                const filteredHead = payrollHead.find(
                  (res) => res?._id === state.add_headName
                );

                if (
                  state.add_headName &&
                  ["advance payment pay", "advance payment deduction"].includes(
                    String(filteredHead?.headName).toLocaleLowerCase()
                  )
                ) {
                  setDebtState((prev) => ({
                    ...prev,
                    ...(headType === "Deduction" && {
                      depositAmount:
                        Number(getStaffCalculatedDebtData?.depositAmount ?? 0) +
                        Number(state.add_config ?? 0) -
                        Number(e.target.value),
                    }),
                    ...(headType === "Earning" && {
                      withdrawalAmount:
                        Number(
                          getStaffCalculatedDebtData?.withdrawalAmount ?? 0
                        ) -
                        Number(state.add_config ?? 0) -
                        Number(e.target.value),
                    }),
                  }));
                }
              }}
            />
          </Grid>
          <Grid item xs={2}>
            {IndianCurrency(state.add_amount)}
          </Grid>
          <Grid item xs={2}>
            <CustomSelectBox
              native
              displayEmpty
              size="small"
              fullWidth
              value={state.add_paymentMode}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  add_paymentMode: e.target.value,
                }))
              }
            >
              <MenuItem value="" component="option">
                Select
              </MenuItem>
              {PAYMENT_MODE?.map((item) => (
                <MenuItem
                  value={item?.value}
                  key={item?.value}
                  component="option"
                >
                  {item?.name}
                </MenuItem>
              ))}
            </CustomSelectBox>
          </Grid>
          <Grid item xs={2}>
            <CustomTextField
              fullWidth
              type="text"
              placeholder="Type remark"
              value={state.add_remark}
              onChange={(e) =>
                setState((prev) => ({ ...prev, add_remark: e.target.value }))
              }
            />
          </Grid>
          <Grid item xs={1.5}>
            <IconButton onClick={handleAdd}>
              <Check color="success" />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          columns={13}
          className="grid_calculationBox"
        >
          <Grid item xs={4}>
            <Typography className="head_text">{totalText}</Typography>
          </Grid>
          <Grid item>
            <Typography className="head_text">{total}</Typography>
          </Grid>
        </Grid>
      </TableWrapper>
    </>
  );
};
export default TableForEarningDeduction;
const TableWrapper = styled(Box)`
  width: 100%;
  border: 1px solid #b6c8d6;
  border-radius: 15px;
  background-color: #fff;
  .head_text {
    font-size: 14px;
    font-weight: 600;
  }
  .value_text {
    font-size: 13px;
    font-weight: 600;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  .grid_content {
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    margin: 0 !important;
  }
  .grid__head {
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    background-color: #f6f8fc;
    border-radius: 15px 15px 0px 0px;
    margin: 0 !important;
    padding-bottom: 5px;
  }
  .grid_calculationBox {
    padding-left: 5px;
    padding-right: 5px;
    background: rgba(8, 76, 132, 0.1);
    border-top: 1px solid #b6c8d6;
    border-radius: 0px 0px 15px 15px;
    width: 100%;
    margin: 0 !important;
    padding-bottom: 5px;
  }
`;
