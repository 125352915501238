import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  Grid,
  MenuItem,
  Stack,
  useMediaQuery,
} from "@mui/material";
import "react-notifications/lib/notifications.css";
import styled from "styled-components";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

import { Controller, useForm } from "react-hook-form";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { Category, Community } from "app/config/administrativeConstants";
import { DISABLITY_LIST } from "app/config/administrative.constant.ts";
import PreviewOffline from "./PreviewOflline";
const CustomLabel = ({ title }) => {
  return (
    <Typography
      sx={{
        fontSize: "14px",
        color: (theme) => theme.palette.primary.main,
        fontWeight: 500,
      }}
    >
      {title}
    </Typography>
  );
};
const CustomInput = ({ control, name, rules, placeholder = "placeholder" }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <CustomTextField
          size="small"
          fullWidth
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          error={!!error}
          helperText={error ? error.message : null}
        />
      )}
    />
  );
};
const CustomSelect = ({ control, name, rules, options = [] }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <CustomSelectBox
          native
          size="small"
          fullWidth
          displayEmpty
          onChange={onChange}
          value={value}
          error={!!error}
        >
          <MenuItem value={""} component="option">
            <em>Select</em>
          </MenuItem>
          {options?.map((item) => (
            <MenuItem value={item} key={item} component="option">
              {item}
            </MenuItem>
          ))}
        </CustomSelectBox>
      )}
    />
  );
};
const CustomDatePicker = ({ control, name, rules }) => {
  return (
    control && (
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <DatePicker
            inputFormat="DD-MM-YYYY"
            onChange={(e) => onChange(e)}
            value={value}
            renderInput={(params) => (
              <CustomTextField {...params} size="small" fullWidth />
            )}
          />
        )}
      />
    )
  );
};

const defaultValues = {
  regNumber: "",
  admissionNumber: "",
  srNumber: "",
  bookNumber: "",
  penNumber: "",
  fullName: "",
  fatherName: "",
  motherName: "",
  dob: new Date(),
  category: "",
  firstAdmission: "",
  firstSession: "",
  firstAdmissionDate: new Date(),
  firstAdmissionClass: "",
  lastAdmissionDate: new Date(),
  lastSession: "",
  lastAdmission: "",
  lastAdmissionClass: "",
  annualResult: "Pass",
  relievingRegion: "Study Else where",
  relievingDate: new Date(),
  otherRegion: "",
  ifFailed: "No",
  subjectStudies: [],
  monthUptoPaid: "",
  higherClass: "Yes",
  whichClass: "",
  feeConcession: "No",
  concessionRemark: "",
  totalWorkingDays: "",
  totalPresent: "",
  penNumber_center: "",
  participate: "No",
  generalConduct: "Good",
  certificateDate: new Date(),
  lastUpdate: "",
  otherRemark: "",
  duesCleared: "No",
};

const OfflineTC = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { handleSubmit, control, watch } = useForm({
    defaultValues,
  });
  const [state, setState] = useState({
    dialog: false,
    data: {},
  });
  const onSubmit = (data) => {
    setState((prev) => ({ ...prev, dialog: true, data }));
  };

  return (
    <>
      <Dialog
        open={state.dialog}
        PaperProps={{
          sx: { minWidth: isMobile ? "80vw" : "900px" },
        }}
        onClose={() => setState((prev) => ({ ...prev, dialog: false }))}
      >
        <PreviewOffline data={state.data} />
        <DialogActions>
          <Button
            variant="contained"
            color="error"
            size="large"
            sx={{ textTransform: "capitalize" }}
            onClick={() =>
              setState((prev) => ({ ...prev, dialog: false, data: {} }))
            }
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Container maxWidth="xl">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Wrapper>
            <div className="student_detail__container">
              <Typography
                fontSize={18}
                color="primary"
                fontWeight={600}
                sx={{ mb: 1 }}
              >
                Personal Information
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} md={3}>
                  <TextFieldLabel title={"Fullname"} />
                  <CustomInput
                    control={control}
                    name={"fullName"}
                    placeholder="Type fullname"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextFieldLabel title={"Father Name"} />
                  <CustomInput
                    control={control}
                    name={"fatherName"}
                    placeholder="Type father name"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextFieldLabel title={"Mother Name"} />
                  <CustomInput
                    control={control}
                    name={"motherName"}
                    placeholder="Type mother name"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextFieldLabel title={"D.O.B"} />
                  <CustomDatePicker control={control} name={"dob"} />
                </Grid>

                <Grid item xs={12} md={3}>
                  <TextFieldLabel title={"Category"} />
                  <CustomSelect
                    control={control}
                    name={"category"}
                    options={Category}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <TextFieldLabel title={"Reg. Number"} />
                  <CustomInput
                    control={control}
                    name={"regNumber"}
                    placeholder="Type registration no."
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextFieldLabel title={"Adm. Number"} />
                  <CustomInput
                    control={control}
                    name={"admissionNumber"}
                    placeholder="Type admission no."
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextFieldLabel title={"SR Number"} />
                  <CustomInput
                    control={control}
                    name={"srNumber"}
                    placeholder="Type sr no"
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <TextFieldLabel title={"PEN Number"} />
                  <CustomInput
                    control={control}
                    name={"penNumber"}
                    placeholder="Type pen no"
                  />
                </Grid>
              </Grid>

              <Typography
                fontSize={16}
                color="primary"
                fontWeight={600}
                sx={{ mt: 2, mb: 0.5 }}
              >
                First Admission Detail
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextFieldLabel title={"Session"} />
                  <CustomInput
                    control={control}
                    name={"firstSession"}
                    placeholder="Type session"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <CustomLabel title="Admission Date" />
                  <CustomDatePicker
                    control={control}
                    name={"firstAdmissionDate"}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <CustomLabel title="Admission Class" />
                  <CustomInput
                    control={control}
                    name={"firstAdmissionClass"}
                    placeholder="Type class"
                  />
                </Grid>
              </Grid>

              <Typography
                fontSize={16}
                color="primary"
                fontWeight={600}
                sx={{ mt: 2, mb: 0.5 }}
              >
                Last Class Detail
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <TextFieldLabel title={"Session"} />
                  <CustomInput
                    control={control}
                    name={"lastSession"}
                    placeholder="Type session"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomLabel title="Admission Date" />
                  <CustomDatePicker
                    control={control}
                    name={"lastAdmissionDate"}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomLabel title="Admission Class" />
                  <CustomInput
                    control={control}
                    name={"lastAdmissionClass"}
                    placeholder="Type class"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomLabel title="Annual Exam Result" />
                  <CustomSelect
                    control={control}
                    name={"annualResult"}
                    options={["Pass", "Fail"]}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomLabel title="Relieving Reason" />
                  <CustomInput
                    control={control}
                    name={"relievingRegion"}
                    placeholder="Type relieving reason"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomLabel title="Relieving Date" />
                  <CustomDatePicker control={control} name={"relievingDate"} />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomLabel title="Whether failed if so (onec/twice) in same class :" />
                  <CustomSelect
                    control={control}
                    name={"ifFailed"}
                    options={["Yes", "No"]}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ pt: 1 }}>
                <Grid item xs={!isMobile && 4}>
                  <CustomLabel title="Whether the pupil has paid all dues of the School? * " />
                  <CustomSelect
                    control={control}
                    name={"duesCleared"}
                    options={["Yes", "No"]}
                  />
                </Grid>
                <Grid item xs={!isMobile && 4}>
                  <CustomLabel title="Whether qualified for promotion to higher class" />
                  <CustomSelect
                    control={control}
                    name={"higherClass"}
                    options={["Yes", "No"]}
                  />
                </Grid>
                <Grid item xs={!isMobile && 4}>
                  <CustomLabel title="If so, to which class" />
                  <CustomInput
                    control={control}
                    name={"whichClass"}
                    placeholder="Type class"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ pt: 1 }}>
                <Grid item xs={!isMobile && 4}>
                  <CustomLabel title="Any fee concession availed" />
                  <CustomSelect
                    control={control}
                    name={"feeConcession"}
                    options={["Yes", "No"]}
                  />
                </Grid>
                {watch("feeConcession") === "Yes" && (
                  <Grid item xs={!isMobile && 4}>
                    <CustomLabel title="if so the nature of such concession, Fill Remarks" />
                    <CustomInput
                      control={control}
                      name={"concessionRemark"}
                      placeholder="Type class"
                    />
                  </Grid>
                )}
                <Grid item xs={!isMobile && 4}>
                  <CustomLabel title="Total no of working Days" />
                  <CustomInput
                    control={control}
                    name={"totalWorkingDays"}
                    placeholder="Type working days"
                  />
                </Grid>
                <Grid item xs={!isMobile && 4}>
                  <CustomLabel title="Total Present" />
                  <CustomInput
                    control={control}
                    name={"totalPresent"}
                    placeholder="Type present days"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ pt: 1 }}>
                <Grid item xs={12} md={4}>
                  <CustomLabel title="Whether participated in NCC/Boy Scout/Girl-Guide" />
                  <CustomSelect
                    control={control}
                    name={"participate"}
                    options={["Yes", "No"]}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <CustomLabel title="Subject Studies (like. Hindi, English, Math. etc) " />
                  <CustomInput
                    control={control}
                    name={"subjectStudies"}
                    placeholder="Type subjects"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomLabel title="Book Number" />
                  <CustomInput
                    control={control}
                    name={"bookNumber"}
                    placeholder="Type book number"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ pt: 1 }}>
                <Grid item xs={12} md={3}>
                  <CustomLabel title="General Conduct" />
                  <CustomSelect
                    control={control}
                    name={"generalConduct"}
                    options={["Good", "Poor"]}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <CustomLabel title="Certificate Issue Date" />
                  <CustomDatePicker
                    control={control}
                    name={"certificateDate"}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <CustomLabel title="Any other remarks" />
                  <CustomInput
                    control={control}
                    name={"otherRemark"}
                    placeholder="Type book number"
                  />
                </Grid>
              </Grid>
            </div>
            <Stack direction="row" justifyContent="flex-end" sx={{ my: 2 }}>
              <Button variant="contained" size="large" type="submit">
                Priview
              </Button>
            </Stack>
          </Wrapper>
        </form>
      </Container>
    </>
  );
};

export default OfflineTC;

const Wrapper = styled.div`
  padding: 1rem 0;
  .student_detail__container {
    background: #ffffff;
    border: 1px solid #b6c8d6;
    border-radius: 15px;
    padding: 1.5rem 2rem;
  }
  .student_detail__img {
    height: 80px;
    width: 80px;
    border-radius: 10px;
    object-fit: contain;
    margin-bottom: 1rem;
  }
  .student_personal__detail {
    background: rgba(208, 208, 226, 0.08);
    border: 1px solid #d0d0e2;
    border-radius: 15px;
    padding: 1rem;
  }
  .student_admission__detail {
    border: 1px solid #d0d0e2;
    border-radius: 15px;
    flex: 1;
  }
  .divider {
    background-color: #d0d0e2;
    height: 1.5px;
  }

  .container {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    align-items: center;
    justify-content: space-between;
  }
  .container-sm {
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    align-items: center;
    justify-content: space-around;
  }
  .container-element {
    display: flex;
    flex-direction: column;
  }
  .container-element p {
    font-weight: 600;
    color: #0c2f49;
    font-size: 16px;
  }
  .container-element h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0c2f49;
    opacity: 0.75;
  }
  .container-element-extra {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .container-element-extra p {
    font-weight: 600;
    color: #0c2f49;
    font-size: 16px;
  }
  .container-element-extra h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0c2f49;
    opacity: 0.75;
  }
  .element-wrapper {
    padding: 25px;
    background: rgba(208, 208, 226, 0.08);
    border: 1px solid #d0d0e2;
    border-radius: 15px;
  }

  .border {
    background: #ffffff;
    border: 1px solid #b6c8d6;
    border-radius: 12px;
  }
  .upperTypo {
    font-weight: 600;
    font-size: 12px;
    text-align: center;
  }
  .upperContent {
    justify-content: space-between;
    /* padding: 15px; */
  }
  .leftTypo {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
  }
  .rightTypo {
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    margin-left: 4em;
    margin-right: 2em;
  }
  .bottomContent {
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    margin-left: 1em;
    margin-right: 3em;
  }
`;
