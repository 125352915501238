import {
  Box,
  Radio,
  Stack,
  styled,
  Typography,
  Grid,
  Container,
  Drawer,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Chart from "./Chart";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { selectActiveSession } from "app/modules/schools/selectors";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "react-query";
import {
  deleteCollectionHead,
  deleteExpenseHead,
  getLedgerReport,
} from "app/services/ledgerAccount";

import { toast } from "react-toastify";

import CustomLoader from "app/components/common/CustomLoader";

import { IndianCurrency } from "app/utils/helper";

import AddCollectionHeadDialog from "app/components/common/Dialogs/ledger/AddCollectionHeadDialog";
import AddExpenseDialogHead from "app/components/common/Dialogs/ledger/AddExpenseHeadDialog";
import BarChartFilter from "app/components/common/Dialogs/ledger/BarChartFilter";
import DeleteDialog from "app/components/ConfirmationDialog/DeleteDialog";
import DialogHeader from "app/components/common/DialogHeader";
import ExpenseSubHeadsPage from "../ExpenseSubHeads";
import SubHeadsPage from "../SubHeadsPage";
import { Helmet } from "react-helmet";

const CollectionvsExpense = () => {
  const [state, setState] = useState({
    cardView_paylog: null,
    showHeadCollectionDialog: false,
    showCollectionId: "",
    showHeadCollectionName: "",
    showHeadExpenseDialog: false,
    showHeadExpenseId: "",
    showHeadExpenseName: "",
  });
  const [modals, setModals] = useState({
    editCollection: false,
    editExpense: false,
    createCollection: false,
    createExpense: false,
    miscExpense: false,
    transportExpense: false,
    miscCollection: false,
  });
  const [ledgerData, setLedgerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [dialogs, setDialogs] = useState({
    collection: false,
    collectionDelete: false,
    expenseDelete: false,
    headName: "",
    collectionHeadId: "",
    expenseHeadId: "",
  });
  const [expensesDialog, setExpenseDialogs] = useState({
    add: false,
    headId: "",
  });

  const selectedSession = useSelector(selectActiveSession);

  const [views, setViews] = useState({
    name: "card",
  });

  const handleChange = (e) => {
    const { value } = e.target;
    setViews({ name: value });
  };
  const handleFilter = (payload) => {
    getLedgerReportFiltered({ session: selectedSession, ...payload });
  };
  const handleFilterCardView = (payload) => {
    setState((prev) => ({ ...prev, cardView_paylog: payload }));
  };

  const handleCloseDeleteCollectionDialog = () => {
    setDialogs((prev) => ({ ...prev, collectionDelete: false }));
  };

  const handleCloseDeleteExpenseDialog = () => {
    setDialogs((prev) => ({ ...prev, expenseDelete: false }));
  };

  const handledeleteCollectionHead = () => {
    deleteCollectionHeadMutate(dialogs?.collectionHeadId);
  };
  const handledeleteExpenseHead = () => {
    deleteExpenseHeadMutate(dialogs?.expenseHeadId);
  };
  const handleCloseShowCollectionHeadDialog = () => {
    setState((prev) => ({
      ...prev,
      showCollectionId: "",
      showHeadCollectionDialog: false,
      showHeadCollectionName: "",
    }));
  };
  const handleCloseShowExpenseHeadDialog = () => {
    setState((prev) => ({
      ...prev,
      showHeadExpenseId: "",
      showHeadExpenseDialog: false,
      showHeadExpenseName: "",
    }));
  };
  const {
    isLoading,
    isFetching,
    refetch: getLedgerReportRefetch,
  } = useQuery({
    queryKey: ["getLedgerReport", state.cardView_paylog, selectedSession],
    queryFn: () => {
      if (state.cardView_paylog) {
        return getLedgerReport({
          session: selectedSession,
          ...state?.cardView_paylog,
        });
      }
      return getLedgerReport({ session: selectedSession });
    },
    onSuccess: (data) => {
      setLedgerData(data.data);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const {
    mutate: getLedgerReportFiltered,
    isLoading: getLedgerReportFilteredLoading,
  } = useMutation(getLedgerReport, {
    onSuccess: (res) => {
      setFilteredData(res.data);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const {
    mutate: deleteCollectionHeadMutate,
    isLoading: deleteCollectionHeadLoading,
  } = useMutation(deleteCollectionHead, {
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      setDialogs((prev) => ({
        ...prev,
        collectionDelete: false,
        collectionHeadId: "",
      }));
      getLedgerReportRefetch();
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const {
    mutate: deleteExpenseHeadMutate,
    isLoading: deleteExpenseHeadLoading,
  } = useMutation(deleteExpenseHead, {
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      setDialogs((prev) => ({
        ...prev,
        expenseDelete: false,
        expenseHeadId: "",
      }));
      getLedgerReportRefetch();
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  return (
    <>
      <Helmet>
        <title>Ledger Report</title>
      </Helmet>
      <CustomLoader
        show={isLoading || isFetching || getLedgerReportFilteredLoading}
      />
      {/* collection head drawer */}
      <Drawer
        open={state.showHeadCollectionDialog}
        onClose={handleCloseShowCollectionHeadDialog}
        anchor="right"
        PaperProps={{
          sx: { width: { xs: "98%", md: "70vw" } },
        }}
      >
        <DialogHeader
          title={state.showHeadCollectionName}
          handleClose={handleCloseShowCollectionHeadDialog}
        />
        <SubHeadsPage id={state.showCollectionId} />
      </Drawer>
      {/* expense head drawer */}
      <Drawer
        open={state.showHeadExpenseDialog}
        onClose={handleCloseShowExpenseHeadDialog}
        anchor="right"
        PaperProps={{
          sx: { width: { xs: "98%", md: "80vw" } },
        }}
      >
        <DialogHeader
          title={state.showHeadExpenseName}
          handleClose={handleCloseShowExpenseHeadDialog}
        />
        <ExpenseSubHeadsPage id={state.showHeadExpenseId} />
      </Drawer>

      {/* Add collection head dialog */}
      <AddCollectionHeadDialog
        open={modals.createCollection}
        onClose={() => {
          setModals((prev) => ({ ...prev, createCollection: false }));
        }}
        refetch={getLedgerReportRefetch}
      />
      {/* Add expense head dialog */}
      <AddExpenseDialogHead
        open={modals.createExpense}
        onClose={() => {
          setModals((prev) => ({ ...prev, createExpense: false }));
        }}
        refetch={getLedgerReportRefetch}
      />
      {/* Delete Dialog Confirmation of Collection*/}
      <DeleteDialog
        open={dialogs.collectionDelete}
        handleClose={handleCloseDeleteCollectionDialog}
        handleDelete={handledeleteCollectionHead}
        loading={deleteCollectionHeadLoading}
      />

      {/* Delete Dialog Confirmation of Expense*/}
      <DeleteDialog
        open={dialogs.expenseDelete}
        handleClose={handleCloseDeleteExpenseDialog}
        handleDelete={handledeleteExpenseHead}
        loading={deleteExpenseHeadLoading}
      />

      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" gap={3} mt={2}>
          <Stack direction="row" alignItems="center">
            <Radio
              value="card"
              name="view"
              checked={views.name === "card"}
              onChange={handleChange}
            />
            <Typography sx={{ fontSize: 16, color: "#0C2F49" }}>
              Card View
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center">
            <Radio
              name="view"
              checked={views.name === "chart"}
              value="chart"
              onChange={handleChange}
            />
            <Typography sx={{ fontSize: 16, color: "#0C2F49" }}>
              Bar Chart View
            </Typography>
          </Stack>
        </Stack>

        {/* card view */}
        {views.name === "card" && (
          <>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <BarChartFilter mutate={handleFilterCardView} />
              <Typography
                sx={{
                  color: "#191e22",
                  fontWeight: "700",
                  fontSize: { xs: "14px", md: "22px" },
                }}
              >
                Total Balance: {IndianCurrency(ledgerData?.totalBalanceAmount)}
              </Typography>
            </Stack>
            <Stack
              direction="column"
              alignItems="center"
              sx={{ mt: 2 }}
              gap={1.5}
            >
              <Box
                width="100%"
                height="100%"
                sx={{
                  background: "#fff",
                  borderRadius: "5px",
                  padding: "20px",
                  position: "relative",
                }}
              >
                <Typography
                  sx={{
                    color: "#0C2F49",
                    fontWeight: "700",
                    fontSize: { xs: "14px", md: "22px" },
                    marginBottom: "20px",
                  }}
                >
                  Total Collection:{" "}
                  {IndianCurrency(ledgerData?.totalCollectionAmount)}
                </Typography>
                <Grid
                  container
                  spacing={1.5}
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                >
                  {ledgerData?.fetchAllCollectionHead?.map((item) => (
                    <Grid item>
                      <CardWrapper
                        sx={{
                          background: "rgba(89, 172, 24, 0.05)",
                          border: "1px solid rgba(89, 172, 24, 0.2)",
                          position: "relative",
                        }}
                      >
                        <Stack
                          sx={item?._id && { cursor: "pointer" }}
                          onClick={() => {
                            if (item?._id) {
                              setState((prev) => ({
                                ...prev,
                                showHeadCollectionDialog: true,
                                showCollectionId: item?._id,
                                showHeadCollectionName:
                                  item?.collectionHeadName,
                              }));
                            }
                          }}
                          direction="row"
                          alignItems="center"
                          gap={1}
                        >
                          <ImgWrapper>
                            <img
                              src="/ledger_report_img2.png"
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "contain",
                              }}
                              alt="ekalsutra-ledger-report"
                            />
                          </ImgWrapper>
                          <CardTitle>
                            {IndianCurrency(item?.totalAmount)}
                          </CardTitle>
                        </Stack>
                        <CardSubTitle
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "80%",
                          }}
                        >
                          {item?.collectionHeadName}
                        </CardSubTitle>
                        <Stack
                          position="absolute"
                          right="0"
                          alignItems="center"
                          justifyContent="space-between"
                          height="100%"
                          padding="10px"
                          gap={1}
                        >
                          <Box
                            onClick={() => {
                              setDialogs((prev) => ({
                                ...prev,
                                collectionDelete: true,
                                collectionHeadId: item?._id,
                              }));
                            }}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            <img
                              alt="edit"
                              src="/delete-icon.png"
                              style={{
                                height: "26px",
                                width: "26px",
                                objectFit: "contain",
                              }}
                            />
                          </Box>
                          {item.description && (
                            <CustomTooltip
                              title={
                                <Stack
                                  direction="column"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  spacing={1}
                                >
                                  <Typography>{item?.description}</Typography>
                                </Stack>
                              }
                              arrow
                            >
                              <Box
                                sx={{
                                  padding: "0 10px",
                                  border: "1px solid #333",
                                  borderRadius: "50%",
                                  color: "#333",
                                  fontSize: "1rem",

                                  background: "#fff",
                                }}
                              >
                                !
                              </Box>
                            </CustomTooltip>
                          )}
                        </Stack>
                      </CardWrapper>
                    </Grid>
                  ))}
                  <Grid item>
                    <CardWrapper
                      sx={{
                        background: "rgba(89, 172, 24, 0.05)",
                        border: "1px solid rgba(89, 172, 24, 0.2)",
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        marginRight={2}
                        gap={1}
                        onClick={() => {
                          setModals((prev) => ({
                            ...prev,
                            createCollection: true,
                          }));
                        }}
                      >
                        <AddIcon
                          sx={{
                            fontSize: "5rem",
                            color: "rgb(89, 172, 24)",
                            padding: "0 10px",
                            borderRadius: "50%",
                            border: "1px solid #333",
                            background: "#fff",
                            cursor: "pointer",
                          }}
                        />
                      </Stack>
                    </CardWrapper>
                  </Grid>
                </Grid>
              </Box>
              <Box
                width="100%"
                height="100%"
                sx={{
                  background: "#fff",
                  borderRadius: "5px",
                  padding: "20px",
                }}
              >
                <Typography
                  sx={{
                    color: "#0C2F49",
                    fontWeight: "700",
                    fontSize: { xs: "14px", md: "22px" },
                    marginBottom: "20px",
                  }}
                >
                  Total Expenses:{" "}
                  {/* {ledgerData?.totalExpenseAmount?.toLocaleString("en-IN")} */}
                  {IndianCurrency(ledgerData?.totalExpenseAmount)}
                </Typography>
                <Grid
                  container
                  spacing={1.5}
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                >
                  {ledgerData?.fetchAllExpenseHead?.map((item) => (
                    <Grid item>
                      <CardWrapper
                        sx={{
                          background: "rgba(255, 236, 236, 0.7)",
                          border: " 1px solid #F4C2C2",
                          position: "relative",
                        }}
                      >
                        <Stack
                          direction="row"
                          sx={item?._id && { cursor: "pointer" }}
                          alignItems="center"
                          gap={1}
                          onClick={() => {
                            if (item?._id) {
                              setState((prev) => ({
                                ...prev,
                                showHeadExpenseDialog: true,
                                showHeadExpenseId: item?._id,
                                showHeadExpenseName: item?.expenseHeadName,
                              }));
                            }
                          }}
                        >
                          <ImgWrapper>
                            <img
                              src="/ledger_report_img3.png"
                              style={{
                                height: "100%",
                                width: "100%",
                                objectFit: "contain",
                              }}
                              alt="ekalsutra-ledger-report"
                            />
                          </ImgWrapper>
                          <CardTitle>
                            &#8377;
                            {item?.totalAmount?.toLocaleString("en-IN") || 0}
                          </CardTitle>
                        </Stack>
                        <CardSubTitle
                          sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "80%",
                          }}
                        >
                          {item?.expenseHeadName}
                        </CardSubTitle>
                        <Stack
                          position="absolute"
                          right="0"
                          alignItems="center"
                          justifyContent="space-between"
                          height="100%"
                          padding="10px"
                        >
                          <Box
                            onClick={() => {
                              setDialogs((prev) => ({
                                ...prev,
                                expenseDelete: true,
                                expenseHeadId: item?._id,
                              }));
                            }}
                            sx={{
                              cursor: "pointer",
                            }}
                          >
                            <img
                              alt="edit"
                              src="/delete-icon.png"
                              style={{
                                height: "26px",
                                width: "26px",
                                objectFit: "contain",
                              }}
                            />
                          </Box>
                          {item.description && (
                            <CustomTooltip
                              title={
                                <Stack
                                  direction="column"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  spacing={1}
                                >
                                  <Typography>{item?.description}</Typography>
                                </Stack>
                              }
                              arrow
                            >
                              <Box
                                sx={{
                                  padding: "0 10px",
                                  border: "1px solid #333",
                                  borderRadius: "50%",
                                  color: "#333",
                                  fontSize: "1rem",

                                  background: "#fff",
                                }}
                              >
                                !
                              </Box>
                            </CustomTooltip>
                          )}
                        </Stack>
                      </CardWrapper>
                    </Grid>
                  ))}
                  <Grid item>
                    <CardWrapper
                      sx={{
                        background: "rgba(255, 236, 236, 0.7)",
                        border: " 1px solid #F4C2C2",
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        marginRight="2rem"
                        gap={1}
                        onClick={() => {
                          setModals((prev) => ({
                            ...prev,
                            createExpense: true,
                          }));
                        }}
                      >
                        <AddIcon
                          sx={{
                            fontSize: "5rem",
                            color: "#F4C2C2",
                            padding: "0 10px",
                            borderRadius: "50%",
                            border: "1px solid #333",
                            background: "#fff",
                            cursor: "pointer",
                          }}
                        />
                      </Stack>
                    </CardWrapper>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </>
        )}
        {/* card view */}

        {/* table view */}
        {(views.name === "table" || views.name === "chart") && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            spacing={5}
            marginTop={4}
            marginBottom={1}
          >
            <CardWrapper
              sx={{
                background: "rgba(89, 172, 24, 0.05)",
                border: "1px solid rgba(89, 172, 24, 0.2)",
              }}
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <ImgWrapper>
                  <img
                    src="/ledger_report_img2.png"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    alt="ekalsutra-ledger-report"
                  />
                </ImgWrapper>
                <CardTitle>
                  &#8377;
                  {filteredData?.totalCollectionAmount?.toLocaleString("en-IN")}
                </CardTitle>
              </Stack>
              <CardSubTitle>Total Collection</CardSubTitle>
            </CardWrapper>
            <CardWrapper
              sx={{
                background: "rgba(255, 236, 236, 0.7)",
                border: " 1px solid #F4C2C2",
              }}
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <ImgWrapper>
                  <img
                    src="/ledger_report_img3.png"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    alt="ekalsutra-ledger-report"
                  />
                </ImgWrapper>
                <CardTitle>
                  {IndianCurrency(filteredData?.totalExpenseAmount)}
                </CardTitle>
              </Stack>
              <CardSubTitle>Total Expense</CardSubTitle>
            </CardWrapper>
            <CardWrapper
              sx={{
                background: "rgba(244, 242, 255, 0.7)",
                border: "1px solid #D3CCF9",
              }}
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <ImgWrapper>
                  <img
                    src="/ledger_report_img1.png"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    alt="ekalsutra-ledger-report"
                  />
                </ImgWrapper>
                <CardTitle>
                  {IndianCurrency(filteredData?.totalBalanceAmount)}
                </CardTitle>
              </Stack>
              <CardSubTitle>Total balance</CardSubTitle>
            </CardWrapper>
            <BarChartFilter mutate={handleFilter} />
          </Stack>
        )}

        {/* chart view */}
        {views.name === "chart" && (
          <>
            <Stack marginTop={2} marginBottom={2}>
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  textTransform: "uppercase",
                  margin: "10px 0",
                }}
              >
                Collections
              </Typography>
              <Stack alignItems="center" justifyContent="center">
                <Chart
                  type="collection"
                  data={ledgerData?.fetchAllCollectionHead}
                />
              </Stack>
            </Stack>
            <Stack>
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  textTransform: "uppercase",
                  margin: "10px 0",
                }}
              >
                Expenses
              </Typography>
              <Stack alignItems="center" justifyContent="center">
                <Chart type="expense" data={ledgerData?.fetchAllExpenseHead} />
              </Stack>
            </Stack>
          </>
        )}
        {/* chart view */}
        {/* </Container> */}
      </Container>
    </>
  );
};

export default CollectionvsExpense;

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: "16px",
    width: "200px",
    borderRadius: "10px",
    boxShadow: "0px 4px 4px rgba(199, 198, 198, 0.25)",
    height: "5rem",
  },
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.2rem",
  marginBottom: "10px",
  fontWeight: 600,
  color: "#0C2F49",
}));

const CardWrapper = styled(Box)(({ theme }) => ({
  borderRadius: "15px",
  width: "270px",
  height: "100px",
  paddingLeft: "22px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const ImgWrapper = styled(Box)(({ theme }) => ({
  height: "35px",
  width: "40px",
}));

const CardTitle = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  textTransform: "uppercase",
  color: "rgba(12, 47, 73, 1)",
  fontWeight: 600,
  letterSpacing: "0.03em",
}));

const CardSubTitle = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  textTransform: "uppercase",
  color: "rgba(12, 47, 73, 1)",
  fontWeight: 500,
  letterSpacing: "0.1em",
}));
